<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              Editing {{ existAnnouncement.title }}
            </h1>
            <p class="log_info">
              Created by {{ existAnnouncement.createdBy ? existAnnouncement.createdBy.name : '' }} on {{ dateFormatWithTime(existAnnouncement.createdAt) }} <br>Last updated on {{ dateFormatWithTime(existAnnouncement.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>For Nirvana Agent SG</strong> This is the control panel where administrators can edit the content for the agent-facing mobile app.</span>
      </div>
    </b-alert>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="announcementEditForm"
        #default="{invalid}"
      >
        <b-card
          header-tag="header"
        >
          <b-row>
            <b-col cols="md-12">
              <b-form-group
                label="Title*"
                label-for="h-announcement-title"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  vid="title"
                  rules="required"
                >
                  <b-form-input
                    id="h-announcement-title"
                    v-model="title"
                    placeholder="Type the announcement title"
                    :state="(errors.length > 0 || titleValidation) ? false : null"
                    name="title"
                    @input="titleValidation == true ? titleValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="titleValidation"
                    class="text-danger"
                  >
                    {{ titleError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-12">
              <validation-provider
                #default="{ errors }"
                name="Category"
                vid="category"
                rules="required"
              >
                <b-form-group
                  label="Category*"
                  label-for="h-announcement-category"
                  label-cols-md="2"
                  :state="(errors.length > 0 || categoryValidation) ? false : null"
                >
                  <v-select
                    id="h-announcement-category"
                    v-model="category"
                    label="name"
                    multiple
                    placeholder="Select category"
                    :options="categoryOptions"
                    :reduce="name => name._id"
                    :clearable="true"
                    @input="categoryValidation == true ? categoryValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="categoryValidation"
                    class="text-danger"
                  >
                    {{ categoryError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-12">
              <validation-provider
                #default="{ errors }"
                name="Push To"
                vid="user"
                rules="required"
              >
                <b-form-group
                  label="Push To*"
                  label-for="h-announcement-user"
                  label-cols-md="2"
                  :state="(errors.length > 0 || userValidation) ? false : null"
                >
                  <v-select
                    id="h-announcement-user"
                    v-model="user"
                    label="name"
                    multiple
                    placeholder="Select who do you want to push to"
                    :options="userOptions"
                    :reduce="name => name._id"
                    :clearable="true"
                    @input="userValidation == true ? userValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="userValidation"
                    class="text-danger"
                  >
                    {{ userError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-12">
              <validation-provider
                #default="{ errors }"
                name="Published by"
                vid="department"
                rules="required"
              >
                <b-form-group
                  label="Published by*"
                  label-for="h-announcement-department"
                  label-cols-md="2"
                  :state="(errors.length > 0 || departmentValidation) ? false : null"
                >
                  <v-select
                    id="h-announcement-department"
                    v-model="department"
                    label="name"
                    multiple
                    placeholder="Select your department"
                    :options="departmentOptions"
                    :reduce="name => name._id"
                    :clearable="true"
                    @input="departmentValidation == true ? departmentValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="departmentValidation"
                    class="text-danger"
                  >
                    {{ departmentError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-12">
              <b-form-group
                label="Show In Highlights?*"
                label-for="h-activity-type-highlight"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Show In Highlights"
                  vid="highlight"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-activity-type-highlight"
                    v-model="highlight"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                  >
                    <b-form-radio
                      v-for="(option, kindex) in requiredOptions"
                      :key="kindex"
                      :value="option.value"
                    >
                      <feather-icon
                        v-if="highlight === option.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="highlight == 'yes'">
              <b-col
                md="12"
                class="my-2"
              >
                <b-form-group
                  label="Highlight Image*"
                  label-cols-md="2"
                  label-for="image"
                >
                  <div class="d-flex justify-content-start">
                    <div
                      class="image__block"
                    >
                      <b-img
                        class="mb-1 mb-sm-0 user__avatar img-preview-block  wh-100"
                        :src="fileSrc ? fileSrc : defaultImage"
                      />
                    </div>

                    <div
                      class="upload__buttons my-auto mx-2"
                    >
                      <b-form-file
                        ref="fileInput"
                        v-model="image"
                        type="file"
                        accept="image/jpeg, image/jpg, image/png"
                        style="display: none"
                        @input="setImage"
                      />
                      <b-button
                        variant="primary"
                        class="mr-50"
                        @click="$refs.fileInput.$el.childNodes[0].click()"
                      >
                        Upload
                      </b-button>
                      <b-button
                        v-if="fileSrc"
                        variant="outline-primary"
                        class="mr-50"
                        @click="removeFileSrc()"
                      >
                        Remove
                      </b-button>
                      <br>
                      <span class="mt-50 d-block">Uploading an image is required for highlighted announcements. The image should not exceed 10 MB and must be in JPEG or PNG format. For the best results, use dimensions of 736px width by 420px height.</span>
                    </div>
                  </div>
                  <small class="text-danger">{{ fileError }}</small>
                </b-form-group>
              </b-col>
              <b-col cols="md-6">
                <b-form-group
                  label="Highlight from*"
                  label-for="h-event-start"
                  label-cols-md="4"
                >
                  <b-input-group>
                    <flat-pickr
                      id="h-event-start"
                      v-model="start"
                      class="form-control flat-pickr-group"
                      :config="{ wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', minDate: 'today', disableMobile: true }"
                      @input="startValidation == true ? startValidation = false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="CalendarIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="startValidation"
                    class="text-danger"
                  >
                    {{ startError }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="md-6" />

              <b-col cols="md-6">
                <b-form-group
                  label="Highlight till*"
                  label-for="h-event-end"
                  label-cols-md="4"
                >
                  <b-input-group>
                    <flat-pickr
                      id="h-event-end"
                      v-model="end"
                      class="form-control flat-pickr-group"
                      :config="{ wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', minDate: 'today', disableMobile: true }"
                      @input="endValidation == true ? endValidation = false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="CalendarIcon"
                        class="cursor-pointer"
                        data-toggle
                        size="18"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="endValidation"
                    class="text-danger"
                  >
                    {{ endError }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="md-6" />
            </template>

            <b-col cols="md-12">
              <b-form-group
                label="Announcement content*"
                label-for="h-announcement-title"
                label-cols-md="2"
              >
                <quill-editor
                  ref="quillTextEditor"
                  class="editor d-block"
                  :value="content"
                  :options="editorOption"
                  @change="onEditorChange"
                />
                <small
                  v-if="contentValidation"
                  class="text-danger"
                >
                  {{ contentError }}
                </small>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-nav class="wrap-border save-nav">
          <li class="nav-item">
            <span>You’re currently editing the announcement. Please save to avoid losing your data.</span>
          </li>
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              @click="cancelEdit"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              @click="saveAsDraft()"
            >
              Save as Draft
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="content != '' ? invalid : true"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav, BAlert, BFormRadioGroup, BFormRadio, BImg, BFormFile,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,
    BAlert,
    BFormRadioGroup,
    BFormRadio,
    BImg,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,

    quillEditor,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      image: null,
      fileSrc: '',
      fileName: '',
      category: [],
      department: [],
      user: [],
      title: '',
      content: '',
      status: 'published',
      highlight: 'no',
      start: '',
      end: '',
      imageError: 'Valid image is required',
      imageValidation: false,
      fileError: '',
      categoryError: 'Valid category is required',
      titleError: 'Valid title is required',
      contentError: 'Valid content is required',
      categoryValidation: false,
      titleValidation: false,
      contentValidation: false,
      statusValidation: false,
      statusError: 'Valid status is required',
      highlightError: 'Valid highlight is required',
      highlightValidation: false,
      departmentError: 'Valid department is required',
      departmentValidation: false,
      userError: 'Valid user is required',
      userValidation: false,
      startError: 'Valid start is required',
      startValidation: false,
      endError: 'Valid end is required',
      endValidation: false,
      statusOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Inactive', code: 'inactive' },
      ],
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      trueFalseOptions: [{ title: 'Yes', code: 'yes' }, { title: 'No', code: 'no' }],

      categoryOptions: [],
      userOptions: [],
      departmentOptions: [],
      existAnnouncement: {},

      // validation rules
      required,
    }
  },

  beforeMount() {
    this.$http.get('customer/announcements/respond-with/category-options')
      .then(response => {
        this.categoryOptions = response.data.categoryOptions || []
        this.departmentOptions = response.data.departmentOptions || []
        this.userOptions = response.data.userOptions || []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  created() {
    this.$http.get(`customer/announcements/${this.$route.params.id}`)
      .then(response => {
        const timezone = process.env.VUE_APP_TIME_ZONE
        this.existAnnouncement = response.data
        this.category = response.data.categories || []
        this.department = response.data.departments || []
        this.user = response.data.to || []
        this.title = response.data.title || ''
        this.highlight = response.data.highlight ? 'yes' : 'no'
        this.start = response.data.fromDate ? moment(response.data.fromDate).utcOffset(timezone).format('DD/MM/YYYY HH:mm') : ''
        this.end = response.data.toDate ? moment(response.data.toDate).utcOffset(timezone).format('DD/MM/YYYY HH:mm') : ''
        this.fileSrc = response.data.image || ''
        this.fileName = response.data.fileName || ''
        this.content = response.data.content || ''
        this.status = response.data.status || 'published'
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    saveAsDraft() {
      this.status = 'draft'
      this.submitForm()
    },
    onEditorChange(value) {
      this.content = value.html
    },
    setImage() {
      if (this.image) {
        if (this.image && this.image.size <= 5242880) {
          const reader = new FileReader()
          reader.readAsDataURL(this.image)
          reader.onload = () => {
            this.fileSrc = reader.result
            this.fileError = ''
            this.fileName = this.image.name
          }
        } else {
          this.fileSrc = null
          this.fileName = this.existAnnouncement.fileName || ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File size not accepted more than 5MB',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    removeFileSrc() {
      this.fileSrc = null
      this.image = null
      this.fileName = this.existAnnouncement.fileName || ''
    },
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will <strong>undo any changes</strong> you have made. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.go(-1)
            // this.$router.push({ name: 'agencies-announcements-index', params: { type: 'all-announcement' } })
          }
        })
    },
    submitForm() {
      this.$refs.announcementEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('categories', JSON.stringify(this.category))
          formData.append('departments', JSON.stringify(this.department))
          formData.append('users', JSON.stringify(this.user))
          formData.append('title', this.title)
          formData.append('highlight', this.highlight)
          formData.append('start', this.start)
          formData.append('end', this.end)
          formData.append('image', this.fileSrc)
          formData.append('fileName', this.fileName)
          formData.append('content', this.content)
          formData.append('status', this.status)

          this.$http.patch(`customer/announcements/${this.$route.params.id}/update`, formData)
            .then(() => {
              // if (this.$route.query.category) {
              //   this.$router.push({ name: 'agencies-announcement-categories-show', params: { id: this.$route.query.category } })
              // } else {
              //   this.$router.push({ name: 'agencies-announcements-index', params: { type: 'all-announcement' } })
              // }

              this.$swal({
                title: 'Announcement Updated',
                html: 'Changes made to this announcement has been saved.',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonText: 'Return to Announcements',
                allowOutsideClick: false,
                confirmButtonText: 'View Entry',
                customClass: {
                  confirmButton: 'btn btn-primary ml-1',
                  cancelButton: 'btn btn-outline-primary',
                },
                buttonsStyling: false,
                width: 550,
              })
                .then(result => {
                  if (result.value) {
                    this.$router.push({ name: 'agencies-announcements-show', params: { id: this.$route.params.id } })
                  } else {
                    this.$router.push({ name: 'agencies-announcements-index', params: { type: 'all-announcement' } })
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'categories') {
                    this.categoryError = validationError.msg
                    this.categoryValidation = true
                  } else if (validationError.param === 'title') {
                    this.titleError = validationError.msg
                    this.titleValidation = true
                  } else if (validationError.param === 'content') {
                    this.contentError = validationError.msg
                    this.contentValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
  setup() {
    const editorOption = {
      modules: {
        // toolbar: '#quill-editor',
        toolbar: [
          ['bold', 'italic', 'underline'],
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ['clean'],
          ['link', 'image', 'video'],
        ],
      },
      theme: 'snow',
      placeholder: '',
    }

    return {
      editorOption,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style scoped>
 .editor {
      min-height: 450px;
      overflow: hidden;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
</style>
